// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PlacementRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "productId": "shirt",
    "decoration": "teamName",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "document-center",
      "offset": {
        "x": "0",
        "y": "-550"
      }
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "cutLineBox.width - cm2px(20)"
    },
    "textSize": "in2px(2)",
    "scaleFactors": [
      "0.8",
      "1"
    ],
    "scaleFactorNames": [
      "Medium",
      "Large"
    ]
  },
  {
    "productId": "shirt",
    "decoration": "playerNumber",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "document-center",
      "offset": {
        "x": "0",
        "y": "0"
      }
    },
    "textSize": "in2px(8)",
    "scaleFactors": [
      "0.8",
      "0.9",
      "1"
    ],
    "scaleFactorNames": [
      "Small",
      "Medium",
      "Large"
    ]
  },
  {
    "productId": "shirt",
    "decoration": "teamLogo",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "document-center",
      "offset": {
        "x": "400",
        "y": "-900"
      }
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "productId": "shirt",
    "decoration": "playerNumber",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "document-center",
      "offset": {
        "x": "0",
        "y": "0"
      }
    },
    "textSize": "in2px(10)",
    "scaleFactors": [
      "0.6",
      "0.8",
      "1"
    ],
    "scaleFactorNames": [
      "Small",
      "Medium",
      "Large"
    ]
  },
  {
    "productId": "shirt",
    "decoration": "playerName",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "document-center",
      "offset": {
        "x": "0",
        "y": "-700"
      }
    },
    "boundingBox": {
      "height": "in2px(2.5)",
      "width": "cutLineBox.width - cm2px(20)"
    },
    "textSize": "in2px(2.5)",
    "scaleFactors": [
      "0.8",
      "1"
    ],
    "scaleFactorNames": [
      "Medium",
      "Large"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PlacementRow[]>> = data;

export type Placement = typeof typed[number];

export { typed as PLACEMENTS };

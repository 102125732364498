// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignPartRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "designId": "camo",
    "pieceNameGlob": "{front,back,sleeve*}",
    "productIdGlob": "*",
    "assetName": "designs/camo",
    "flipX": false,
    "repeatX": true,
    "repeatY": true
  },
  {
    "designId": "circles",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/circles",
    "flipX": false,
    "repeatX": true,
    "repeatY": true
  },
  {
    "designId": "lines",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/lines",
    "flipX": false,
    "repeatX": true,
    "repeatY": true
  },
  {
    "designId": "savanna",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/savanna",
    "flipX": false,
    "repeatX": true,
    "repeatY": true
  },
  {
    "designId": "sunrise",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/sunrise",
    "flipX": false,
    "repeatX": false,
    "repeatY": false
  },
  {
    "designId": "bubbles",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/bubbles",
    "flipX": false,
    "repeatX": false,
    "repeatY": false
  },
  {
    "designId": "coerver",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/coerver_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "1.01"
  },
  {
    "designId": "coerver",
    "pieceNameGlob": "back",
    "productIdGlob": "*",
    "assetName": "designs/coerver_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "1.01"
  },
  {
    "designId": "coerver",
    "pieceNameGlob": "sleeve{Left,Right}",
    "productIdGlob": "*",
    "assetName": "designs/coerver_sleeve",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "1.04"
  },
  {
    "designId": "metta",
    "pieceNameGlob": "front",
    "productIdGlob": "*",
    "assetName": "designs/metta_front",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "0.85"
  },
  {
    "designId": "metta",
    "pieceNameGlob": "back_top",
    "productIdGlob": "*",
    "assetName": "designs/metta_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "0.85"
  },
  {
    "designId": "metta",
    "pieceNameGlob": "back_bottom",
    "productIdGlob": "*",
    "assetName": "designs/metta_back_bottom",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "0.85"
  },
  {
    "designId": "metta",
    "pieceNameGlob": "shoulders*",
    "productIdGlob": "*",
    "assetName": "designs/metta_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "0.85"
  },
  {
    "designId": "metta",
    "pieceNameGlob": "sleeve*",
    "productIdGlob": "*",
    "assetName": "designs/metta_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "0.85"
  },
  {
    "designId": "metta",
    "pieceNameGlob": "neckband",
    "productIdGlob": "*",
    "assetName": "designs/metta_back",
    "flipX": false,
    "repeatX": false,
    "repeatY": false,
    "scale": "0.85"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignPartRow[]>> = data;

export type DesignPart = typeof typed[number];

export { typed as DESIGN_PARTS };

// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { DesignRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "camo",
    "name": "Camo",
    "colors": 3,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  },
  {
    "id": "circles",
    "name": "Circles",
    "colors": 3,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  },
  {
    "id": "lines",
    "name": "Lines",
    "colors": 3,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  },
  {
    "id": "savanna",
    "name": "Savanna",
    "colors": 3,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  },
  {
    "id": "bubbles",
    "name": "Bubbles",
    "colors": 3,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  },
  {
    "id": "sunrise",
    "name": "Sunrise",
    "colors": 3,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  },
  {
    "id": "coerver",
    "name": "Coerver",
    "colors": 3,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  },
  {
    "id": "metta",
    "name": "Metta",
    "colors": 0,
    "limitations": {
      "productIds": [
        "mettaMensShortSleeve"
      ]
    }
  },
  {
    "id": "fullCustom",
    "name": "Full Custom",
    "colors": 0,
    "limitations": {
      "productIds": [
        "shirt"
      ]
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DesignRow[]>> = data;

export type Design = typeof typed[number];

export const DESIGN_INDEX_KEY = "id";
export type DesignIndexKey = "id";
export type DesignId = Design["id"];

let i = 0;
export const DESIGN_DICT = {
  "camo": typed[i++],
  "circles": typed[i++],
  "lines": typed[i++],
  "savanna": typed[i++],
  "bubbles": typed[i++],
  "sunrise": typed[i++],
  "coerver": typed[i++],
  "metta": typed[i++],
  "fullCustom": typed[i++]
} as const;

export { typed as DESIGNS };

// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "white",
    "name": "White",
    "hex": "#fcfcfc"
  },
  {
    "id": "white_+1",
    "name": "White +1",
    "hex": "#ebebeb",
    "parentId": "white"
  },
  {
    "id": "white_+2",
    "name": "White +2",
    "hex": "#cccccc",
    "parentId": "white"
  },
  {
    "id": "white_+3",
    "name": "White +3",
    "hex": "#adadad",
    "parentId": "white"
  },
  {
    "id": "white_+4",
    "name": "White +4",
    "hex": "#858585",
    "parentId": "white"
  },
  {
    "id": "black_-1",
    "name": "Black -1",
    "hex": "#707070",
    "parentId": "black"
  },
  {
    "id": "black_-2",
    "name": "Black -2",
    "hex": "#5c5c5c",
    "parentId": "black"
  },
  {
    "id": "black_-3",
    "name": "Black -3",
    "hex": "#3d3d3d",
    "parentId": "black"
  },
  {
    "id": "black_-4",
    "name": "Black -4",
    "hex": "#292929",
    "parentId": "black"
  },
  {
    "id": "black",
    "name": "Black",
    "hex": "#000000"
  },
  {
    "id": "red_-2",
    "name": "Red -2",
    "hex": "#f07f7f",
    "parentId": "red"
  },
  {
    "id": "red_-1",
    "name": "Red -1",
    "hex": "#ec5b5b",
    "parentId": "red"
  },
  {
    "id": "red",
    "name": "Red",
    "hex": "#e62b2b"
  },
  {
    "id": "red_+1",
    "name": "Red +1",
    "hex": "#db1a1a",
    "parentId": "red"
  },
  {
    "id": "red_+2",
    "name": "Red +2",
    "hex": "#a41313",
    "parentId": "red"
  },
  {
    "id": "orange_-2",
    "name": "Orange -2",
    "hex": "#ff955c",
    "parentId": "orange"
  },
  {
    "id": "orange_-1",
    "name": "Orange -1",
    "hex": "#ff7a33",
    "parentId": "orange"
  },
  {
    "id": "orange",
    "name": "Orange",
    "hex": "#ff600b"
  },
  {
    "id": "orange_+1",
    "name": "Orange +1",
    "hex": "#f55600",
    "parentId": "orange"
  },
  {
    "id": "orange_+2",
    "name": "Orange +2",
    "hex": "#cc4700",
    "parentId": "orange"
  },
  {
    "id": "lightOrange_-2",
    "name": "Light Orange -2",
    "hex": "#fec872",
    "parentId": "lightOrange"
  },
  {
    "id": "lightOrange_-1",
    "name": "Light Orange -1",
    "hex": "#fdb035",
    "parentId": "lightOrange"
  },
  {
    "id": "lightOrange",
    "name": "Light Orange",
    "hex": "#fd9d06"
  },
  {
    "id": "lightOrange_+1",
    "name": "Light Orange +1",
    "hex": "#f29602",
    "parentId": "lightOrange"
  },
  {
    "id": "lightOrange_+2",
    "name": "Light Orange +2",
    "hex": "#de8a02",
    "parentId": "lightOrange"
  },
  {
    "id": "yellow_-2",
    "name": "Yellow -2",
    "hex": "#ffe95c",
    "parentId": "yellow"
  },
  {
    "id": "yellow_-1",
    "name": "Yellow -1",
    "hex": "#ffe433",
    "parentId": "yellow"
  },
  {
    "id": "yellow",
    "name": "Yellow",
    "hex": "#fad900"
  },
  {
    "id": "yellow_+1",
    "name": "Yellow +1",
    "hex": "#e0c200",
    "parentId": "yellow"
  },
  {
    "id": "yellow_+2",
    "name": "Yellow +2",
    "hex": "#b89f00",
    "parentId": "yellow"
  },
  {
    "id": "lightGreen_-2",
    "name": "Light Green -2",
    "hex": "#ceeb5c",
    "parentId": "lightGreen"
  },
  {
    "id": "lightGreen_-1",
    "name": "Light Green -1",
    "hex": "#bee425",
    "parentId": "lightGreen"
  },
  {
    "id": "lightGreen",
    "name": "Light Green",
    "hex": "#a3c418"
  },
  {
    "id": "lightGreen_+1",
    "name": "Light Green +1",
    "hex": "#87a314",
    "parentId": "lightGreen"
  },
  {
    "id": "lightGreen_+2",
    "name": "Light Green +2",
    "hex": "#5a6d0d",
    "parentId": "lightGreen"
  },
  {
    "id": "green_-2",
    "name": "Green -2",
    "hex": "#78d35f",
    "parentId": "green"
  },
  {
    "id": "green_-1",
    "name": "Green -1",
    "hex": "#5dca3f",
    "parentId": "green"
  },
  {
    "id": "green",
    "name": "Green",
    "hex": "#4bae30"
  },
  {
    "id": "green_+1",
    "name": "Green +1",
    "hex": "#3e9027",
    "parentId": "green"
  },
  {
    "id": "green_+2",
    "name": "Green +2",
    "hex": "#29601a",
    "parentId": "green"
  },
  {
    "id": "mint_-2",
    "name": "Mint -2",
    "hex": "#b7e1d2",
    "parentId": "mint"
  },
  {
    "id": "mint_-1",
    "name": "Mint -1",
    "hex": "#7dcaad",
    "parentId": "mint"
  },
  {
    "id": "mint",
    "name": "Mint",
    "hex": "#52b892"
  },
  {
    "id": "mint_+1",
    "name": "Mint +1",
    "hex": "#419f7d",
    "parentId": "mint"
  },
  {
    "id": "mint_+2",
    "name": "Mint +2",
    "hex": "#358266",
    "parentId": "mint"
  },
  {
    "id": "lightBlue_-2",
    "name": "Light Blue -2",
    "hex": "#c6eafb",
    "parentId": "lightBlue"
  },
  {
    "id": "lightBlue_-1",
    "name": "Light Blue -1",
    "hex": "#8dd5f7",
    "parentId": "lightBlue"
  },
  {
    "id": "lightBlue",
    "name": "Light Blue",
    "hex": "#59c2f3"
  },
  {
    "id": "lightBlue_+1",
    "name": "Light Blue +1",
    "hex": "#2db3e0",
    "parentId": "lightBlue"
  },
  {
    "id": "lightBlue_+2",
    "name": "Light Blue +2",
    "hex": "#0d87bf",
    "parentId": "lightBlue"
  },
  {
    "id": "blue_-2",
    "name": "Blue -2",
    "hex": "#7c86f4",
    "parentId": "blue"
  },
  {
    "id": "blue_-1",
    "name": "Blue -1",
    "hex": "#5663f0",
    "parentId": "blue"
  },
  {
    "id": "blue",
    "name": "Blue",
    "hex": "#2435ec"
  },
  {
    "id": "blue_+1",
    "name": "Blue +1",
    "hex": "#1222ce",
    "parentId": "blue"
  },
  {
    "id": "blue_+2",
    "name": "Blue +2",
    "hex": "#0b1583",
    "parentId": "blue"
  },
  {
    "id": "lilac_-2",
    "name": "Lilac -2",
    "hex": "#cd92f2",
    "parentId": "lilac"
  },
  {
    "id": "lilac_-1",
    "name": "Lilac -1",
    "hex": "#ac49e9",
    "parentId": "lilac"
  },
  {
    "id": "lilac",
    "name": "Lilac",
    "hex": "#8418c7"
  },
  {
    "id": "lilac_+1",
    "name": "Lilac +1",
    "hex": "#601291",
    "parentId": "lilac"
  },
  {
    "id": "lilac_+2",
    "name": "Lilac +2",
    "hex": "#3c0b5b",
    "parentId": "lilac"
  },
  {
    "id": "violet_-2",
    "name": "Violet -2",
    "hex": "#e5acec",
    "parentId": "violet"
  },
  {
    "id": "violet_-1",
    "name": "Violet -1",
    "hex": "#d57ae1",
    "parentId": "violet"
  },
  {
    "id": "violet",
    "name": "Violet",
    "hex": "#bb2fcd"
  },
  {
    "id": "violet_+1",
    "name": "Violet +1",
    "hex": "#a62ab7",
    "parentId": "violet"
  },
  {
    "id": "violet_+2",
    "name": "Violet +2",
    "hex": "#791e85",
    "parentId": "violet"
  },
  {
    "id": "pink_-2",
    "name": "Pink -2",
    "hex": "#fbc6f1",
    "parentId": "pink"
  },
  {
    "id": "pink_-1",
    "name": "Pink -1",
    "hex": "#f68de3",
    "parentId": "pink"
  },
  {
    "id": "pink",
    "name": "Pink",
    "hex": "#f145d2"
  },
  {
    "id": "pink_+1",
    "name": "Pink +1",
    "hex": "#e411bd",
    "parentId": "pink"
  },
  {
    "id": "pink_+2",
    "name": "Pink +2",
    "hex": "#ab0d8e",
    "parentId": "pink"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorRow[]>> = data;

export type Color = typeof typed[number];

export const COLOR_INDEX_KEY = "id";
export type ColorIndexKey = "id";
export type ColorId = Color["id"];

let i = 0;
export const COLOR_DICT = {
  "white": typed[i++],
  "white_+1": typed[i++],
  "white_+2": typed[i++],
  "white_+3": typed[i++],
  "white_+4": typed[i++],
  "black_-1": typed[i++],
  "black_-2": typed[i++],
  "black_-3": typed[i++],
  "black_-4": typed[i++],
  "black": typed[i++],
  "red_-2": typed[i++],
  "red_-1": typed[i++],
  "red": typed[i++],
  "red_+1": typed[i++],
  "red_+2": typed[i++],
  "orange_-2": typed[i++],
  "orange_-1": typed[i++],
  "orange": typed[i++],
  "orange_+1": typed[i++],
  "orange_+2": typed[i++],
  "lightOrange_-2": typed[i++],
  "lightOrange_-1": typed[i++],
  "lightOrange": typed[i++],
  "lightOrange_+1": typed[i++],
  "lightOrange_+2": typed[i++],
  "yellow_-2": typed[i++],
  "yellow_-1": typed[i++],
  "yellow": typed[i++],
  "yellow_+1": typed[i++],
  "yellow_+2": typed[i++],
  "lightGreen_-2": typed[i++],
  "lightGreen_-1": typed[i++],
  "lightGreen": typed[i++],
  "lightGreen_+1": typed[i++],
  "lightGreen_+2": typed[i++],
  "green_-2": typed[i++],
  "green_-1": typed[i++],
  "green": typed[i++],
  "green_+1": typed[i++],
  "green_+2": typed[i++],
  "mint_-2": typed[i++],
  "mint_-1": typed[i++],
  "mint": typed[i++],
  "mint_+1": typed[i++],
  "mint_+2": typed[i++],
  "lightBlue_-2": typed[i++],
  "lightBlue_-1": typed[i++],
  "lightBlue": typed[i++],
  "lightBlue_+1": typed[i++],
  "lightBlue_+2": typed[i++],
  "blue_-2": typed[i++],
  "blue_-1": typed[i++],
  "blue": typed[i++],
  "blue_+1": typed[i++],
  "blue_+2": typed[i++],
  "lilac_-2": typed[i++],
  "lilac_-1": typed[i++],
  "lilac": typed[i++],
  "lilac_+1": typed[i++],
  "lilac_+2": typed[i++],
  "violet_-2": typed[i++],
  "violet_-1": typed[i++],
  "violet": typed[i++],
  "violet_+1": typed[i++],
  "violet_+2": typed[i++],
  "pink_-2": typed[i++],
  "pink_-1": typed[i++],
  "pink": typed[i++],
  "pink_+1": typed[i++],
  "pink_+2": typed[i++]
} as const;

export { typed as COLORS };
